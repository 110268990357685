import type { ButtonHTMLAttributes, ComponentProps, FC, ReactNode } from 'react';
import { ButtonPrimary } from './buttons/ButtonPrimary';
import { ButtonSecondary } from './buttons/ButtonSecondary';
import type { IconName } from './ui/Icon';
import type { Link } from '@remix-run/react';

export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary'
}

export enum ButtonSize {
  Default = 'default',
  Small = 'small'
}

export enum ButtonColor {
  Purple = 'purple',
  Orange = 'orange'
}

export type ButtonProps = {
  className?: string;
  children?: ReactNode;
  variant?: ButtonVariant;
  size?: ButtonSize;
  onClick?: () => void;
  to?: string;
  /**
   * @deprecated Please use type property due conform with HTTML standards
   */
  submit?: boolean;
  iconName?: IconName;
  target?: string;
  isLoading?: boolean;
  reloadDocument?: boolean;
  disabled?: boolean;
  color?: ButtonColor;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  name?: string;
  value?: string;
  prefetch?: ComponentProps<typeof Link>['prefetch'];
}

export const Button = ( props: ButtonProps ) => {
  const variants : Record<ButtonVariant, FC<ButtonProps>> = {
    [ButtonVariant.Primary]: ButtonPrimary,
    [ButtonVariant.Secondary]: ButtonSecondary
  }

  const { children, variant = ButtonVariant.Primary, size = ButtonSize.Default } = props;
  const Component = variants[ variant ];

  if ( Component ) {
    return (
      <Component 
        { ...{
          ...props,
          variant,
          size
        } }
      >
        { children }
      </Component>
    )
  }
  
  return null;
}
