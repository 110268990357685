import { Link } from '@remix-run/react';
import { ButtonSize } from '../Button';
import type { ButtonProps } from '../Button';
import { Icon } from '../ui/Icon';
import classNames from 'classnames';

export const ButtonSecondary = ( { className, children, to, onClick, size = ButtonSize.Default, submit, iconName, target, reloadDocument, type, name, value, prefetch } : ButtonProps ) => {

  const Inner = () => {
    return (
      <div className={ 'bg-gradient-to-b from-[#564AD7] to-[#91A8FC] rounded-lg flex w-full relative p-[1px] hover:from-[#91A8FC] hover:to-[#91A8FC] active:from-[#382BC0] active:to-[#5167EB]' }>
        <div className='rounded-[7px] p-[2px] bg-[#0C102E] w-full'>
          <div className={ classNames(
            'bg-gradient-to-b from-[#0C102E] to-[#282C46] border rounded-md border-[rgba(110,162,253,0.14)] px-4 py-2 font-onest font-semibold text-white text-center',
            iconName ? 'pr-12' : '',
            size === ButtonSize.Default ? 'text-base' : 'text-sm'
          ) }>
            { children }
          </div>
          { iconName &&
            <div className={ 'absolute right-0 inset-y-0 flex items-center justify-center pr-4 drop-shadow-[0px_1px_1px_rgba(0,0,0,0.25)] text-white' }>
              <Icon
                name={ iconName }
                className={ 'text-base' } />
            </div>
          }
        </div>
      </div>
    )
  }

  if ( to ) {
    return (
      <Link
        to={ to }
        className={ className }
        target={ target }
        onClick={ onClick }
        reloadDocument={ reloadDocument }
        prefetch={ prefetch }
      >
        <Inner />
      </Link>
    )
  }

  return (
    <button
      className={ className }
      type={ type ? type : submit ? 'submit' : 'button' }
      value={ value }
      name={ name }
      onClick={ onClick }  
    >
      <Inner />
    </button>
  )
}
